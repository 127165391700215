export default class Navbar {
	constructor() {
		this.navbar = document.querySelector('.navbar')
		this.submenuButton = [...this.navbar.querySelectorAll('.submenu-btn')]
		this.mql = window.matchMedia('(max-width: 1024px)')
		this.hamburger = document.querySelector('.hamburger')
		this.activeTab = this.navbar.querySelector('.navbar-listItem.active')
		this.activeTabLink = this.activeTab?.querySelector('.navbar-listItemLink')
		this.slideBarElement = this.navbar.querySelector('.navbar-slideBar')
		this.activeLinkComputedWidth = 0

		this.toggleSubmenu = this.toggleSubmenu.bind(this)
		this.initSlideBar = this.initSlideBar.bind(this)
	}

	/**
	 * Init Navbar
	 */
	init() {
		this.activeLinkComputedWidth = this.getActiveLinkComputedWidth()
		this.addEvents()
		this.screenSizeWatch()
	}

	/**
	 * Get active link computed width
	 * @returns {Number} - active link computed width
	 */
	getActiveLinkComputedWidth() {
		if (this.activeTabLink) {
			const computedStyle = window.getComputedStyle(this.activeTabLink)
			const paddingLeft = parseFloat(computedStyle.paddingLeft)
			const paddingRight = parseFloat(computedStyle.paddingRight)
			return this.activeTab.offsetWidth - paddingLeft - paddingRight
		}
	}

	/**
	 * Add events
	 */
	addEvents() {
		document.fonts.ready.then(() => {
			this.initSlideBar()
		})
		this.submenuButton.forEach((submenu) => {
			submenu.addEventListener('click', this.toggleSubmenu)
		})
	}

	/**
	 * Toggle submenu
	 * @param {Event} event - event
	 */
	toggleSubmenu(event) {
		const target = event.target
		const submenu = target.parentNode.querySelector('.submenu-list')
		if (this.mql.matches) {
			target.classList.toggle('opened')
			submenu.classList.toggle('opened')
			submenu.style.maxHeight = submenu.style.maxHeight
				? submenu.removeAttribute('style')
				: `${submenu.scrollHeight}px`
		}
	}

	/**
	 * Screen size watch
	 */
	screenSizeWatch() {
		this.mql.onchange = (e) => {
			const isMobile = !!e.matches

			if (!isMobile) {
				;[...this.navbar.querySelectorAll('.opened')].forEach((item) => {
					item.classList.remove('opened')
				})
				;[...this.navbar.querySelectorAll('.submenu-list')].forEach((item) => {
					item.removeAttribute('style')
				})
			}
		}
	}

	/**
	 *
	 * @param {HTMLElement} element
	 * @returns {String} - position in pixels
	 */
	getElementCenterPosition(element) {
		return `${element.offsetLeft + element.offsetWidth - (element.offsetWidth * 50) / 100}px`
	}

	/**
	 * Init slide bar
	 */
	initSlideBar() {
		setTimeout(() => {
			if (this.activeTab) {
				this.slideBarElement.style.left = this.getElementCenterPosition(this.activeTab)
				this.slideBarElement.classList.add('active')
			}
			this.slideBar()
		}, 1000)
	}

	/**
	 * Underlines the hovered item in the menu
	 */
	slideBar() {
		this.navbar.addEventListener('mouseover', (e) => {
			const element = e.target
			const link = element.closest('a')
			if (!link) return

			const computedStyle = window.getComputedStyle(element)
			const paddingLeft = parseFloat(computedStyle.paddingLeft)
			const paddingRight = parseFloat(computedStyle.paddingRight)

			const width = element.offsetWidth - paddingLeft - paddingRight

			if (element.nodeName.toLowerCase() === 'a') {
				this.slideBarElement.style.left = this.getElementCenterPosition(element.parentNode)
				this.slideBarElement.style.width = `${width}px`
				this.slideBarElement.classList.add('active')
			}
		})

		this.navbar.addEventListener('mouseout', () => {
			if (this.activeTab) {
				this.slideBarElement.style.left = this.getElementCenterPosition(this.activeTab)
				this.slideBarElement.style.width = `${this.activeLinkComputedWidth}px`
			} else {
				this.slideBarElement.classList.remove('active')
			}
		})
	}
}
